const menuItems = [
    // {
    //   id: "services",
    //   title: "Services",
    //   navLink: "/services"
    // },
    {
      id: "configuration",
      title: "Configuration",
      navLink: "/configuration"
    },
    {
        id: "tokenManagement",
        title: "Token Management",
        navLink: "/token-management"
    },
  ];

export default menuItems
  