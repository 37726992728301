// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-datagrid .dx-header-row {
  background-color: #191b2a;
  color: white;
  border: 0.1vmin solid #00C800;
  height: 3vmin;
}

.dx-datagrid-rowsview .dx-row {
    border-left: 0.1vmin solid #00C800;
    border-right: 0.1vmin solid #00C800;
    height: 3vmin;
}

.dx-datagrid .dx-row > td, 
.dx-datagrid .dx-header-row > td {
    border: 0.1vmin solid #00C800;
}

.dx-datagrid .dx-page {
    color: #171B2A; 
    background-color: #FAFBFC; 
}

.dx-datagrid .dx-page.dx-selection {
    color: white; 
    background-color: #00C800;
}

.dx-datagrid .dx-prev-button, 
.dx-datagrid .dx-next-button {
    color: #00C800;
}

.dx-datagrid .dx-page-size.dx-selection {
    background-color: #00C800;
    color: #fff;
}

.dx-datagrid .dx-page.dx-selection {
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/property/PropertyList.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;AACf;;AAEA;IACI,kCAAkC;IAClC,mCAAmC;IACnC,aAAa;AACjB;;AAEA;;IAEI,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".dx-datagrid .dx-header-row {\n  background-color: #191b2a;\n  color: white;\n  border: 0.1vmin solid #00C800;\n  height: 3vmin;\n}\n\n.dx-datagrid-rowsview .dx-row {\n    border-left: 0.1vmin solid #00C800;\n    border-right: 0.1vmin solid #00C800;\n    height: 3vmin;\n}\n\n.dx-datagrid .dx-row > td, \n.dx-datagrid .dx-header-row > td {\n    border: 0.1vmin solid #00C800;\n}\n\n.dx-datagrid .dx-page {\n    color: #171B2A; \n    background-color: #FAFBFC; \n}\n\n.dx-datagrid .dx-page.dx-selection {\n    color: white; \n    background-color: #00C800;\n}\n\n.dx-datagrid .dx-prev-button, \n.dx-datagrid .dx-next-button {\n    color: #00C800;\n}\n\n.dx-datagrid .dx-page-size.dx-selection {\n    background-color: #00C800;\n    color: #fff;\n}\n\n.dx-datagrid .dx-page.dx-selection {\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
