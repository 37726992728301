// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigationConfig_icon__JNREn {
    width: 8vmin;
    margin-right: 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/config/navigationConfig.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".icon {\n    width: 8vmin;\n    margin-right: 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `navigationConfig_icon__JNREn`
};
export default ___CSS_LOADER_EXPORT___;
